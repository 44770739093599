
.cstm-bg {
    background: url(https://www.ironfx.com/wp-content/uploads/2024/04/ma-indicators-analysis.gif);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    color: #FCDDE8;
    height: 400px;
    
}

.cstm-bg-dark {
    background: rgb(54,36,101, 0.6);
    border-radius: 15px;
    display: flex;
    align-items:center;
}

