@media print {

    .no-print, .no-print *
    {
        display: none !important;
    }

    div.centerOnPrintedPage {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 90vh;
    }
}
